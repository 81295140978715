.unsupported-browser {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;
  margin: 5rem auto 0 auto;

  &-info {
    &__logo {
      margin: 2rem auto 2rem auto;
      max-width: 17rem;
    }

    &__title {
      font-size: 3.2rem;
      line-height: 3.8rem;
      color: var(--color-primary);
      font-weight: 300;
      margin: 2rem 2rem 2rem 2rem;
      text-align: center;
    }
  }
}
