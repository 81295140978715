.button {
  border-radius: 6px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 1.6rem;
  cursor: pointer;
  border: none;
  border-radius: 5rem;
  box-shadow: none;
  font-family: var(--font-family);
  font-weight: 400;

  &--size-small {
    padding: 0.4rem 2.8rem;
    font-size: var(--font-size-small);
    line-height: 1.8;
  }

  &--size-medium {
    padding: 0.4rem 3.8rem;
    font-size: var(--font-size-default);
    line-height: 2;
  }

  &--size-large {
    padding: 0.6rem 3.8rem;
    font-size: var(--font-size-large);
    line-height: 2.2;
  }

  &--color-primary {
    background: var(--color-primary);
    color: #ffffff;

    &:hover {
      background: var(--color-primary-darker);
    }
  }

  &--color-primary-link {
    padding-left: 0;
    padding-right: 0;
    background: transparent;
    color: var(--color-primary);
    text-decoration: underline;
  }

  &--color-secondary {
    background: #e8e6e6;
    color: #737373;
  }

  &--color-danger-outline {
    border: 1px solid var(--color-danger);
    background: transparent;
    color: var(--color-danger);
  }
}
