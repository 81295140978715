.form-month-year-picker {
  margin-bottom: 2rem;

  &__selects {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__select {
    font-size: var(--font-size-default);
    font-weight: 400;
    font-family: var(--font-family);
    color: var(--color-text-primary);
    padding: 0.9rem 1rem;
    border: 1px solid var(--color-primary);
    border-radius: 7px;
    display: block;
    background: transparent;
    height: 42px;
    margin-top: 0.4rem;
    flex: 1;
    margin-left: 1rem;

    &:active,
    &:focus {
      outline: none;
      border: 2px solid var(--color-primary-darker);
    }
  }

  &__select:first-child {
    margin-left: 0;
  }

  &__select:required:invalid {
    color: gray;
  }

  &__select option[value=''][disabled] {
    display: none;
  }

  &__select option {
    color: black;
  }
}
