.progression-bar {
    list-style: none;
    margin: 0 auto 3rem;
    text-align: center;
    li {
        width: 0.9rem;
        height: 0.9rem;
        display: inline-block;
        margin-right: 0.5rem;
        border-radius: 10rem;
        background: #777;
        opacity: 0.3;
        &.current {
            opacity: 1;
        }
    }
}
