.book-medical-procedures {
  &__other,
  &__extra {
    margin-top: -1rem;
  }

  &__none-option {
    font-weight: bold;
  }
}
