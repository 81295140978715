.form-confirm {
  margin-bottom: 1.8rem;

  &__box {
    border: 1px solid var(--color-primary);
    padding: 0.75rem 1rem;
    border-radius: 6px;
    min-height: 42px;

    &--without-border {
      border: none;
      padding: 0;
      min-height: auto;
    }
  }

  &__main {
    display: flex;
    align-items: center;
  }

  &--checked {
    border-color: var(--color-primary);
  }

  &__checkbox {
    margin-bottom: 0 !important;

    &--has-extra {
      margin-bottom: 0.4rem !important;
    }
  }

  &__view {
    cursor: pointer;
    flex: 1;
    display: flex;
    justify-content: flex-end;
    font-size: 2rem;
  }

  &__extra {
    margin: 0 0 0 2.4rem;
    font-size: var(--font-size-small);
    flex: 1;
    flex-basis: 100%;
    cursor: pointer;
    text-decoration: underline;
    color: var(--color-primary);
  }

  & em {
    color: var(--color-primary);
    font-style: normal;
  }
}
