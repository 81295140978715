.help {
  padding-top: var(--content-padding-top);
  padding-bottom: var(--content-padding-bottom);

  &__contact-title {
    margin-top: 2.5rem;
  }

  &__link {
    margin-bottom: 0;
  }
}
