@import '../../mixins.scss';

.calendar {
  display: block;
  width: 100%;
  border: 1px solid #dddddd;
  border-radius: 7px;
  margin-bottom: 2rem;
  max-width: 40rem;

  &__header {
    padding: 1.1rem 1rem;
    color: var(--color-primary);

    &-inner {
      display: flex;
      align-items: center;
      max-width: 45rem;
      margin: 0 auto;
    }

    &-month {
      display: flex;
      align-items: center;
      font-size: var(--font-size-default);
      cursor: pointer;

      &--current {
        margin-left: auto;
        margin-right: auto;
        font-weight: 400;
        font-size: var(--font-size-large);
        cursor: default;
      }
    }

    &-nav {
      font-size: 2.2rem;
    }
  }

  &__header-days {
    padding: 1rem 1rem;
    border-bottom: 1px solid #dddddd;

    &-inner {
      display: flex;
      align-items: center;
      max-width: 45rem;
      margin: 0 auto;
    }
  }

  &__header-day {
    width: 14.2857%;
    text-align: center;
  }

  &__body-days {
    padding: 1rem 1rem;

    &-inner {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin: 0 auto;
    }
  }

  &__body-day {
    width: 14.2857%;
    cursor: pointer;
    position: relative;

    &-inner {
      padding: 25%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      &:hover {
        background: #e8e8e8;
        color: #fff !important;
        border-radius: 50%;
      }

      &--today {
        background: #9fe1e1;
        color: #fff !important;
        border-radius: 50%;
      }

      &--past {
        color: #dfdfdf;
      }

      &--no-availability {
        color: #dfdfdf;
      }

      &--fully-booked {
        position: relative;

        &::before {
          content: '';
          width: 7px;
          height: 7px;
          border-radius: 7px;
          position: absolute;
          top: 15%;
          right: 15%;
          background: #ff2626;
        }
      }

      &--limited-availability {
        position: relative;

        &::before {
          content: '';
          width: 7px;
          height: 7px;
          border-radius: 7px;
          position: absolute;
          top: 15%;
          right: 15%;
          background: #ffa726;
        }
      }

      &--good-availability {
        position: relative;

        &::before {
          content: '';
          width: 7px;
          height: 7px;
          border-radius: 7px;
          position: absolute;
          top: 15%;
          right: 20%;
          background: #8bc34a;
        }
      }
    }
  }
}
