@import '../../mixins.scss';

.medical {
  padding-top: var(--content-padding-top);
  padding-bottom: var(--content-padding-bottom);

  @include respond(desktop) {
    &__title {
      margin-top: 2rem;
    }
  }
}
