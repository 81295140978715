@import '../../../mixins.scss';

.login {
  @include respond(desktop) {
    margin: 0 auto;
    max-width: var(--width-desktop-smaller);
  }

  &__submit {
    display: flex;
    justify-content: center;
  }

  &__forgotten {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
  }

  &__logo {
    margin: 2rem auto;
    max-width: 10rem;
  }
}
