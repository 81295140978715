.book {
  padding-top: var(--content-padding-top);
  padding-bottom: var(--content-padding-bottom);
}

.book-appointment-details-disclaimer.form-checkbox {
    display: block;
    margin-bottom: 4rem;
    label > span {
        margin-left: 0.25rem;
    }
}

.book-appointment-continue__button.button {
    margin-bottom: 5rem;
}