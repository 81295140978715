@import '../../../../mixins.scss';

.book-menstrual-cycle {
  &__sub-title {
    color: var(--color-text-primary);
  }

  &__month-year-picker {
    select {
      flex: 0 1 15rem;
    }
  }

  &-symptoms {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1.5rem;

    &__item {
      flex: 0 1 100%;

      @include respond(desktop) {
        flex: 0 1 33.3333%;
      }
    }
  }

  &__date-picker-label {
    margin-bottom: 0.6rem;
  }

  &__field-error {
    margin-bottom: 1rem;
  }

  &__none-option {
    label {
      font-weight: bold;
    }
  }
}
