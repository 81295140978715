.book-medical-profile {
  &-height,
  &-weight {
    display: flex;
  }

  &__input-number input {
    min-width: auto;
    width: 15rem;
    margin-right: 1rem;
  }

  &__month-year-picker {
    select {
      flex: 0 1 15rem;
    }
  }

  &__sub-title {
    margin-top: 4rem;
  }

  &__date-picker-label {
    margin-bottom: 0.6rem;
  }
}
