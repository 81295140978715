.form-error {
  margin-bottom: 1.8rem;
  background: var(--color-error-background);
  color: var(--color-danger);
  padding: 1.5rem;
  border-radius: 6px;
  white-space: pre-wrap;

  p {
    margin-bottom: 1.5rem;
  }

  p:last-child {
    margin-bottom: 0;
  }

  a {
    color: var(--color-danger);
    text-decoration: underline;
  }
}
