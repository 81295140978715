.loading-spinner {
  min-height: 4.8rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &--fullscreen {
    width: 100%;
    height: 100vh;
  }

  &__dots {
    margin: 0 auto 0;
    width: 70px;
    text-align: center;
  }

  &__bounce {
    width: 18px;
    height: 18px;
    background-color: var(--color-primary);

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: loading-spinner-bounce 1.4s infinite ease-in-out both;
    animation: loading-spinner-bounce 1.4s infinite ease-in-out both;
  }

  &__bounce--1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  &__bounce--2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
}

@-webkit-keyframes loading-spinner-bounce {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes loading-spinner-bounce {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
