@import '../../mixins.scss';

.home-appointment {
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  margin-bottom: 2rem;

  &:last-child {
    margin-bottom: 0;
  }

  &__info {
    display: flex;
    align-items: flex-start;
    padding: 1rem;

    @include respond(desktop) {
      align-items: center;
      padding: 2rem;
    }
  }

  &__avatar {
    flex: 0 0 7rem;
    max-width: 7rem;
    border-radius: 50%;

    @include respond(desktop) {
      flex: 0 0 12rem;
      max-width: 12rem;
    }
  }

  &__details {
    flex: 1;
    margin-left: 1.5rem;

    @include respond(desktop) {
      margin-left: 3rem;
    }
  }

  &__date {
    font-size: var(--font-size-large);
    font-weight: 600;
    margin-bottom: 0.2rem;

    @include respond(desktop) {
      font-size: 2.6rem;
      margin-bottom: 0.8rem;
    }
  }

  &__time {
    margin-bottom: 0.2rem;

    @include respond(desktop) {
      margin-bottom: 0.5rem;
      font-size: var(--font-size-large);
    }
  }

  &__practitioner {
    margin-bottom: 0;

    @include respond(desktop) {
      font-size: var(--font-size-large);
    }
  }

  &__actions {
    display: flex;
    border-top: 1px solid #eeeeee;
    margin: 0 1rem 0 1rem;
    padding: 1rem;
    align-items: center;
    justify-content: center;
    color: var(--color-primary);
    font-weight: 600;

    @include respond(desktop) {
      font-size: var(--font-size-large);
    }
  }
}
