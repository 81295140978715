.appointment-pre-appointment {
  &__update-title {
    margin-top: 2.5rem;
  }
  &__action-links {
    display: flex;
    flex-direction: column;
  }

  &__another-window {
    font-style: italic;
  }

  &__action-cancel {
    margin-top: 0.1rem;
    justify-content: left;
  }
}
