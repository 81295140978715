@import './mixins.scss';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700&display=swap');

:root {
  --font-family: 'Montserrat', sans-serif;

  --color-primary: #03989e;
  --color-primary-darker: #1ba0a2;
  --color-text-primary: #545454;
  --color-error-background: #ffefef;
  --color-danger: #bd5050;

  --font-size-small: 1.4rem;
  --font-v-size-small: 1.2rem;
  --font-size-default: 1.6rem;
  --font-size-large: 1.8rem;

  --font-size-title-main: 2.2rem;
  --font-size-title-sub: 1.9rem;

  --width-desktop: 65em;
  --width-desktop-smaller: 35em;

  --content-padding-left: 1.2rem;
  --content-padding-right: 1.2rem;
  --content-padding-top: 8.5rem;
  --content-padding-bottom: 8rem;

  @include respond(desktop) {
    --content-padding-left: 0rem;
    --content-padding-right: 0rem;
    --content-padding-top: 12rem;
    --content-padding-bottom: 1rem;
  }
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  /* force inheritance on all elements as box-sizing is not an inherited
  property by default */
  box-sizing: inherit;
}

html {
  font-size: 62.5%; /* This defines what 1rem is (10px) */
}

body {
  box-sizing: border-box;
  background: var(--color-background);
  font-family: var(--font-family);
  font-weight: 400;
  font-size: var(--font-size-default);
  line-height: 2.45rem;
  color: var(--color-text-primary);
}

a {
  color: var(--color-primary);
  text-decoration: none;
}

strong {
  font-weight: 600;
}
