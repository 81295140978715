.book-about-gp {
  &-confirm {
    border: 1px solid #c8c8c8;
    padding: 1rem;
    border-radius: 6px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 2rem;

    &--checked {
      border-color: var(--color-primary);
    }

    &__view {
      margin-left: auto;
      cursor: pointer;
    }

    &__extra {
      margin: 0 0 0 2.4rem;
      font-size: var(--font-size-small);
      flex: 1;
      flex-basis: 100%;
    }
  }

  &__sub-title {
    color: var(--color-text-primary);
  }
}
