.book-appointment-confirm {
  &__pay-amount {
    margin-bottom: 4rem;
    font-size: 6rem;
    line-height: 1;
    font-weight: 300;

    &--small {
      font-size: var(--font-size-large);
    }
  }

  &__flexcol {
    flex-direction: column;
    display: flex;
  }

  &__button {
    border: 1px;
    border-radius: 0px;
  }

  &__sub-title {
    color: var(--color-text-primary);
  }

  &__payment-methods {
    margin-top: 4rem;
    margin-bottom: 1rem;
  }

  &__logos {
    max-width: 35rem;
    display: flex;

    & > img {
      display: block;
      width: 20%;
      padding-right: 0.5rem;
      height: 100%;
    }
  }
}
