.form-checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 1.2rem;

    &--no-margin {
        margin: 0;
    }

    &__label {
        cursor: pointer;
        display: flex;
        align-items: flex-start;
    }

    &__checkbox {
        margin-right: 1rem;
        margin-top: 0.3rem;
        min-width: 1.8rem;
        min-height: 1.8rem;
        flex-shrink: 0
    }

    &__label-highlight {
        margin-left: 0.25rem;
        color: var(--color-primary);
    }
}
