.text-title {
  &--main {
    font-size: var(--font-size-title-main);
    color: var(--color-primary);
    margin-bottom: 2.6rem;
  }

  &--sub {
    font-size: var(--font-size-title-sub);
    color: var(--color-primary);
    margin-bottom: 1.5rem;
  }

  &--body {
    font-size: var(--font-size-default);
    color: var(--color-primary);
    margin-bottom: 0.1rem;
  }

  &--center {
    text-align: center;
  }
}
