.medical-medications {
  padding-top: var(--content-padding-top);
  padding-bottom: var(--content-padding-bottom);

  &__add-medication {
    margin-bottom: 5rem;
  }

  &__field-error {
    margin-bottom: 1.6rem;
  }
}
