.password-and-confirm {
  &__password {
    display: flex;
    align-items: flex-start;
    position: relative;
  }

  &__input {
    flex: 1;
  }

  &__icon {
    margin-top: 3.8rem;
    margin-left: 1rem;
    font-size: 2.2rem;
    position: absolute;
    right: 8px;
  }
}
