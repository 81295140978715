.appointment-in-progress {
  padding-top: var(--content-padding-top);
  padding-bottom: var(--content-padding-bottom);

  &__actions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
