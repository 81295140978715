.field-error {
  font-size: var(--font-size-small);
  margin-top: 0.5rem;
  color: var(--color-danger);

  &__list {
    margin-left: 1.3rem;
  }

  & > li {
    margin-left: 1.4rem;
  }
}
