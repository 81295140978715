.home {
  padding-top: var(--content-padding-top);
  padding-bottom: var(--content-padding-bottom);

  &__title {
    margin: 0 0 1.5rem 0;
    font-size: 3rem;
  }

  &__sub-title {
    margin-bottom: 2rem;
    font-size: 2.2rem;
    color: var(--color-text-primary);
  }

  &__book-appointment {
    margin-bottom: 0rem;
    display: block;

    & > button {
      margin-bottom: 0;
    }

    &--margin-bottom {
      margin-bottom: 2rem;
    }
  }

  &-upcoming-appointments {
    background: rgba(80, 188, 189, 0.07);
    margin-bottom: 3rem;
    padding: 2rem 0;
  }

  &-past-appointments {
    margin-top: 3rem;
  }

  &-cancelled-appointments {
    margin-top: 3rem;
  }

  &-footer {
    font-size: var(--font-size-small);
    text-align: center;
    padding: 1rem;
    margin-top: 5rem;
  }
}
