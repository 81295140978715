@import '../../../mixins.scss';

.book-appointment-details {
  &__info-banner {
    padding-bottom: 0;
  }

  &__notes,
  &__questions {
    margin-bottom: 0 !important;
  }

  &__notes .form-text-area__field,
  &__questions .form-text-area__field {
    height: 10rem;
  }

  &__sub-title {
    color: var(--color-text-primary);
  }

  &-menopause-symptoms {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 3rem;

    &__item {
      flex: 0 1 100%;

      @include respond(desktop) {
        flex: 0 1 33.3333%;
      }
    }

    &__none-option {
      label {
        font-weight: bold;
      }
    }
  }

  &__field-error {
    margin-bottom: 1rem;
  }
}
