.book-medical-history {
  padding-bottom: var(--content-padding-bottom);

  &__sub-title {
    color: var(--color-text-primary);
  }

  &__field-error {
    margin-bottom: 1rem;
  }

  &__none-option {
    label {
      font-weight: bold;
    }
  }
}
