@import '../../../mixins.scss';

.alert-info {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  z-index: 1000;

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
  }

  &__label {
    margin-bottom: 0;
  }

  &__close {
    margin-left: auto;
    cursor: pointer;
    font-size: 2.4rem;
  }

  &__content {
    position: absolute;
    left: 1rem;
    right: 1rem;
    padding: 2rem 1.5rem 2rem 1.5rem;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2), 0px 8px 10px rgba(0, 0, 0, 0.14),
      0px 3px 14px rgba(0, 0, 0, 0.12);
    overflow: auto;

    @include respond(desktop) {
      max-width: 50rem;
      margin: 0 auto;
    }
  }

  &__action {
    margin-bottom: 0;
  }
}
