@import '../../mixins.scss';

.info-banner {
  color: var(--color-primary);
  margin: 0 0 2rem 0;

  @include respond(desktop) {
    margin-left: auto;
    margin-right: auto;
    max-width: var(--width-desktop);
  }

  &__content {
    padding: 1.5rem;
    background: #d8efeb;
    display: flex;
    align-items: center;

    @include respond(desktop) {
      margin-left: var(--content-padding-left);
      margin-right: var(--content-padding-right);
      max-width: var(--width-desktop);
    }
  }

  &__text {
    color: #7c7e7e;
    margin: 0;
    line-height: 1.6;
    font-size: var(--font-size-small);
    flex: 1;
  }

  &__info {
    margin-right: 1rem;
    font-size: 2.5rem;
  }

  &__dismiss {
    margin-left: 1rem;
    cursor: pointer;
    font-size: 2.5rem;
  }
}
