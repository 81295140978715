@import '../../../mixins.scss';

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.book-appointment-date {
  &__availabilities {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 1rem;
  }

  &__label {
    margin-bottom: 0.5rem;
    flex: 0 0 100%;
  }

  &__title {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  &__availability {
    margin-right: 2rem;
    position: relative;
    padding-left: 1.5rem;

    &::before {
      content: '';
      width: 8px;
      height: 8px;
      margin-top: -4px;
      border-radius: 8px;
      position: absolute;
      top: 50%;
      left: 0;
    }

    &--fully-booked {
      &::before {
        background: #ff2626;
      }
    }

    &--limited {
      &::before {
        background: #ffa726;
      }
    }

    &--good {
      &::before {
        background: #8bc34a;
      }
    }
  }
}
.line {
  display: flex;
  flex-grow: 1;
  width: 100%;
  border-top: 1px solid #d9d9d9;
  padding-bottom: 2rem;
}

.calendar-urgency {
  color: #3a3a3a;
  position: relative;
  &-message {
    display: flex;
    align-items: center;
    background: #e9e8e8;
    padding: 1rem;
    max-width: 400px;
    z-index: 100;
    width: 100%;
    animation: fadeIn 3s;
    margin-bottom: 1rem;
    font-size: 1.4rem;
    @include respond(desktop) {
      position: absolute;
      left: 395px;
      top: 15%;
      max-width: 300px;
      font-size: var(--font-size-default);
    }
  }
  &-icon {
    fill: #259591;
    width: 20px;
    height: 20px;
    margin-right: 1rem;
  }
}
