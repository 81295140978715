@import '../../../mixins.scss';

.register {
    @include respond(desktop) {
        margin: 0 auto;
        max-width: var(--width-desktop-smaller);
    }

    &-actions {
        display: flex;
        justify-content: center;
    }

    &__logo {
        margin: 2rem auto;
        max-width: 10rem;
    }
}
