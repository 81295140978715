@import '../../mixins.scss';

.content {
  padding: 0 var(--content-padding-left) 0 var(--content-padding-right);

  @include respond(desktop) {
    margin: 0 auto;
    max-width: var(--width-desktop);
  }
}
