@import '../../../mixins.scss';

.book-appointment-time {
  &__what-time {
    color: var(--color-text-primary);
  }

  &-date {
    display: flex;
    align-items: center;
    padding: 1rem 0;
    margin-bottom: 1.5rem;
    color: var(--color-primary);
    font-size: var(--font-size-title-sub);

    @include respond(desktop) {
      max-width: 40rem;
    }

    &__nav {
      cursor: pointer;
      font-size: 2.2rem;
    }

    &__selected {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__select {
    margin-bottom: 1rem;
  }

  &__slot {
    cursor: pointer;
    margin-bottom: 1rem;
    padding: 0.7rem 8rem;
    min-width: 24rem;
    font-size: var(--font-size-default);
    line-height: 2;
    border: none;
    border-radius: 5rem;
    text-align: center;
    display: block;
    font-size: var(--font-size-default);
    background: var(--color-primary);
    color: #ffffff;

    &:hover {
      background: var(--color-primary-darker);
    }

    &--unavailable {
      color: #6c6c6c;
      background: #ddd;
      border-color: rgba(92, 92, 92, 0.29);
      text-decoration: line-through;
      cursor: not-allowed;

      &:hover {
        background: #ddd;
      }
    }
  }

  &__none-available {
    margin-bottom: 1rem;
    padding: 0.7rem 3rem;
    font-size: var(--font-size-default);
    line-height: 2;
    border: none;
    border-radius: 5rem;
    text-align: center;
    display: inline-block;
    font-size: var(--font-size-default);
    background: var(--color-danger);
    color: #ffffff;
  }
}
