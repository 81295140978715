@import '../../mixins.scss';

.page-header {
    display: flex;
    align-items: center;
    padding-bottom: 1rem;
    padding-left: var(--content-padding-left);
    padding-right: 1rem;
    position: fixed;
    width: 100%;
    background: #fff;
    z-index: 10;

    @include respond(desktop) {
        top: 0;
        left: 2rem;
    }

    &__back-icon {
        margin-right: 0.2rem;
        cursor: pointer;
        color: var(--color-primary);
        font-size: 1.6rem;

        @include respond(desktop) {
            order: 1;
            margin-top: 1rem;
        }
    }

    &__back-title {
        font-size: 1.8rem;
        text-decoration: underline;
    }

    &__title {
        margin: 0;
        font-size: 2.2rem;
        cursor: pointer;

        @include respond(desktop) {
            margin: 1rem 0 0 0;
            order: 2;
            font-size: 2rem;
        }
        &.text-title--main {
            margin-bottom: 0;
        }
    }

    &__logo {
        margin: 0.5rem 0.5rem 0 auto;
        width: 7.25rem;
        background-color: transparent;
        border: none;

        @include respond(desktop) {
            margin: 1rem 1rem 0 0;
            width: 10rem;
            order: 0;
        }
    }
}
