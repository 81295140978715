@import '../../../mixins.scss';

.forgotten-password {
    position: relative;

    @include respond(desktop) {
        margin: 0 auto;
        max-width: var(--width-desktop-smaller);
    }

    &__back {
        margin-top: 2rem;
        display: flex;
        justify-content: center;
    }

    &__actions {
        display: flex;
        justify-content: center;
    }
}
