@import '../../mixins.scss';

.account {
  padding-top: var(--content-padding-top);
  padding-bottom: var(--content-padding-bottom);

  @include respond(desktop) {
    &__title {
      margin-top: 2rem;
    }
  }

  &__logout {
    margin-top: 3rem;
  }
}
