.data-pill {
  border: 1px solid var(--color-text-primary);
  border-radius: 20px;
  padding: 0.3rem 0.7rem 0.3rem 1.5rem;
  display: inline-block;
  cursor: pointer;
  margin: 0 1rem 1rem 0;

  &__content {
    display: flex;
    align-items: center;
  }

  &__icon {
    margin-left: 0.3rem;
    font-size: 2.4rem;
  }
}
