.menu-list {
  &__item {
    display: flex;
    align-items: center;
    padding: 1.2rem 0;
    font-size: var(--font-size-default);
    cursor: pointer;

    &:last-child {
      border-bottom: none;
    }
  }

  &__label {
    margin-right: auto;
    color: #757575;
  }

  &__icon {
    color: #757575;
    font-size: 1.6rem;
  }
}
