.appointment-post-appointment {
  &__doctor-notes {
    color: var(--color-text-primary);
    padding: 0.75rem 1rem;
    border: 1px solid var(--color-primary);
    border-radius: 7px;

    p {
      min-height: var(--font-size-default);
    }
  }

  &__download-letter {
    margin-bottom: 3rem;
  }

  &__medical-notes-title {
    margin-top: 2.5rem;
  }
}
