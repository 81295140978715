.book-medical-medications {
  padding-bottom: var(--content-padding-bottom);

  &__add-medication,
  &__add-allergy {
    margin-bottom: 3rem;
  }

  &__field-error {
    margin-bottom: 1.6rem;
  }

  &__sub-title {
    color: var(--color-text-primary);
  }
}
