@import '../../../mixins.scss';

.menu-list {
  margin-bottom: 2rem;

  @include respond(desktop) {
    margin: 0 auto;
    max-width: var(--width-desktop);
  }
}
