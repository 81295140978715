@import '../../mixins.scss';

.navigation-nav-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  width: 100%;
  border-top: 1px solid #d8d8d8;
  background: #fff;
  z-index: 10;

  &--desktop-only {
    display: none;
  }

  @include respond(desktop) {
    top: 0;
    right: 2rem;
    border-top: none;
    width: auto;
    bottom: auto;
    left: auto;
    min-height: 10rem;

    &--desktop-only {
      display: flex;
    }
  }

  &__tab {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 1rem 0.4rem 1rem;
    flex: 1;
    color: var(--color-text-primary);
    font-weight: 300;

    &--active {
      color: var(--color-primary);
      font-weight: 600;
    }

    @include respond(desktop) {
      margin-left: 1.8rem;
      flex-direction: row;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &__label {
    font-size: var(--font-size-small);

    @include respond(desktop) {
      font-size: var(--font-size-large);
      padding-left: 1rem;
    }
  }

  &__icon {
    color: var(--color-primary);
    font-size: 2.4rem;
  }
}
