@import '../../../mixins.scss';

.form-radio {
    margin-bottom: 1.8rem;

    &__label {
        cursor: pointer;
        margin-bottom: 1rem;
        margin-right: 0;
        display: flex;
        align-items: flex-start;

        @include respond(desktop) {
            margin-right: 2.5rem;
        }
    }

    &__inputs {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;

        @include respond(desktop) {
            flex-direction: row;
            align-items: center;
        }
    }

    &--vertically &__inputs {
        flex-direction: column;
        align-items: flex-start;
    }

    &__radio {
        margin-right: 1rem;
        cursor: pointer;
        min-width: 1.8rem;
        min-height: 1.8rem;
        margin-top: 0.3rem;
        flex-shrink: 0;
    }

    &--three-column .form-radio__inputs {
        @include respond(desktop) {
            display: grid;
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }
    }

}
