.text-body {
  &--default {
    font-size: var(--font-size-default);
    color: var(--color-text-primary);
    margin-bottom: var(--font-size-default);
  }

  &--emphasis {
    font-weight: bold;
  }

  &--semi-bold {
    font-weight: 500;
  }

  &--small {
    font-size: var(--font-size-small);
    font-style: italic;
    margin-bottom: 2.2rem;
  }
  &--v-small {
    font-size: var(--font-v-size-small);
  }

  &.text-style-normal {
    font-style: normal;
  }

  &--center {
    text-align: center;
  }

  &.mt {
    &-xs {
      margin-top: 0.5rem;
    }
  }

  &.mb {
    &-sm {
      margin-bottom: 1rem;
    }
  }

  ul,
  ol {
    margin: 1rem 0 1.5rem 0;
  }

  li {
    margin: 0 0 1rem 1.5rem;
  }

  & > em {
    color: var(--color-primary);
    font-style: normal;
  }
}
