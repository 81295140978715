@import '../../../mixins.scss';

.book-appointment-confirm {
  &__pay-amount {
    margin-bottom: 4rem;
    font-size: 6rem;
    line-height: 1;
    font-weight: 300;

    &--small {
      font-size: var(--font-size-large);
    }
  }

  &__sub-title {
    color: var(--color-text-primary);
  }

  &__pay-code {
    margin-top: 0.8rem;
    @include respond(desktop) {
      margin: 0.8rem;
    }
  }

  &__payment-methods {
    margin-top: 4rem;
    margin-bottom: 1rem;
  }

  &__row {
    display: flex;
    flex-direction: column;
    @include respond(desktop) {
      flex-direction: row;
    }
  }

  &__logos {
    max-width: 22rem;
    display: flex;
    margin-bottom: 1.2rem;

    & > img {
      display: block;
      width: 20%;
      padding-right: 0.5rem;
      height: 100%;
    }
  }

  &__logo-and-label {
    max-width: 25rem;
    display: flex;
    margin-bottom: 1.2rem;

    & > img {
      display: block;
      width: 17%;
      padding-right: 0.5rem;
      height: 100%;
    }
  }
  &__long-logo-and-label {
    max-width: 25rem;
    display: flex;
    padding-top: 0.2rem;

    & > img {
      display: block;
      width: 17%;
      padding-right: 0.5rem;
      padding-top: 0.5rem;
      height: 100%;
    }
  }
}
